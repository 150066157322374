:root {
  --primary: #e8eee8;
  --secondary: #d1d4d8;
  --background: #364b38;
  --chip: #4cbdc0;
}

body {
  margin: 0;
  font-family:  ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--secondary);
}

h1 {
  margin: 0;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
  color: var(--primary);
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: var(--primary);
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: var(--primary);
}

h4 {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: .15rem;
}

h5 {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

p {
  line-height: 1.5;
}

a {
  color:var(--primary);
  text-decoration: none;
  font-weight: 600;
}
a:active {
  color:var(--chip);
}
a:hover {
  color: var(--chip);
}

.fly {
  margin-left: 11rem !important;
  margin-top: -11rem !important;
  transition: margin 3000ms !important;
}